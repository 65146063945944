import {Duration, Job, Project} from '@/features/project/types.ts'

const SHORT_DURATION = 1
const MEDIUM_DURATION = 2
const LONG_DURATION = 3

export const durationToMinutes = (duration: Duration) =>
    duration == 'short' ? SHORT_DURATION : duration == 'medium' ? MEDIUM_DURATION : LONG_DURATION
export const minutesToDuration = (minutes: number): Duration =>
    minutes < SHORT_DURATION ? 'short' : minutes < MEDIUM_DURATION ? 'medium' : 'long'

export const durationToMinutesLeft = {
    short: 11,
    medium: 18,
    long: 28
} as const satisfies Record<Duration, number>

export const findCurrentJob = (project: Project | undefined, jobId: Job['id']) =>
    project?.jobs.find(job => job.id == jobId)
