import axios from 'axios'
import {MediaType, Project, Seniority} from '@/features/project/types.ts'
import {envVars} from '@/envVars.ts'

//Store project
export interface HttpStoreProjectPayload {
    mediaTypes: [MediaType, ...MediaType[]]
    minutes: number
    prompt: string
    seniority: Seniority
}

export const httpStoreProject = (payload: HttpStoreProjectPayload) => {
    return axios.post(`${envVars.VITE_API_ENDPOINT}projects`, payload).then(response => Project.parse(response.data))
}

//Get project
export const httpGetProject = (id: string) => {
    return axios.get(`${envVars.VITE_API_ENDPOINT}projects/${id}`).then(response => Project.parse(response.data))
}

//Create job
export interface HttpCreateJobPayload {
    targetLanguageId: number
    mediaTypes: MediaType[]
}

export const httpCreateJob = (projectId: string, payload: HttpCreateJobPayload) => {
    return axios.post(`${envVars.VITE_API_ENDPOINT}projects/${projectId}/jobs`, payload)
}
