import {AlertCircleIcon} from '@components/ui/Icon'
import i18next from 'i18next'
import Button from '@components/ui/Button'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {useNavigate} from 'react-router-dom'

export const NotFound = () => {
    const navigate = useNavigate()

    return (
        <ErrorBox
            icon={<AlertCircleIcon />}
            title={i18next.t('not_found:title')}
            cta={<Button onClick={() => navigate('/')}>{i18next.t('not_found:cta')}</Button>}
        />
    )
}
