import {Helmet as ReactHelmet} from 'react-helmet'
import {FC} from 'react'

interface HelmetProps {
    title: string
}
export const Helmet: FC<HelmetProps> = ({title}) => {
    return (
        <ReactHelmet>
            <title>{title}</title>
        </ReactHelmet>
    )
}
