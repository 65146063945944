import {StyledSpinner} from '@components/ui/Spinner/style.ts'
import {CSSProperties} from 'react'

type SpinnerProps = {
    bgColor?: CSSProperties['borderColor']
    borderColor?: CSSProperties['borderColor']
    borderWidth?: CSSProperties['borderWidth']
    size: number
}

export const Spinner = ({bgColor, borderColor, borderWidth, size}: SpinnerProps) => (
    <StyledSpinner size={size} bgColor={bgColor} borderColor={borderColor} borderWidth={borderWidth} />
)

Spinner.displayName = 'Spinner'
