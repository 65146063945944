import styled, {css, DefaultTheme} from 'styled-components'
import {spacing} from '@/theme'
import {XCloseIcon} from '@components/ui/Icon'

export const StyledOverlay = styled.div(
    ({theme: {palette, zIndex}}) => css`
        position: fixed;
        inset: 0;
        background: ${palette.neutral['600']}80;
        z-index: ${zIndex.modalOverlay};
    `
)

interface StyledModalProps {
    width?: number
    theme: DefaultTheme
}

export const StyledModal = styled.div(
    ({width, theme: {palette, zIndex}}: StyledModalProps) => css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: ${zIndex.modal};
        border-radius: 12px;
        background: ${palette.neutral.white};
        width: ${width}px;
        max-height: calc(100% - ${spacing * 6}px);
        max-width: calc(100% - ${spacing * 6}px);
        overflow: hidden;
    `
)

export const StyledCloseIcon = styled(XCloseIcon)`
    ${({$closeIconOffset, theme: {spacing}}: {theme: DefaultTheme; $closeIconOffset?: 'sm' | 'md' | 'lg'}) => css`
        position: absolute;
        top: ${$closeIconOffset == 'sm' ? spacing * 4 : $closeIconOffset == 'lg' ? spacing * 6.5 : spacing * 5.5}px;
        left: ${$closeIconOffset == 'sm' ? spacing * 4 : $closeIconOffset == 'lg' ? spacing * 6.5 : spacing * 5.5}px;
        cursor: pointer;
    `}
`
