export const palette = {
    light: {
        neutral: {
            white: '#FFFFFF',
            25: '#FCFCFD',
            50: '#F9FAFB',
            100: '#F2F4F7',
            200: '#EAECF0',
            300: '#D0D5DD',
            400: '#98A2B3',
            500: '#667085',
            600: '#475467',
            700: '#344054',
            800: '#1D2939',
            900: '#101828',
            950: '#0C111D',
            black: '#000000'
        },
        primary: {
            25: '#FFF5E6',
            50: '#FFEED5',
            100: '#FFDDAA',
            200: '#FFCC80',
            300: '#FFBB55',
            400: '#FFAA2A',
            500: '#FF9900',
            600: '#D58000',
            700: '#AA6600',
            800: '#804D00',
            900: '#553300',
            950: '#2A1900'
        },
        secondary: {
            25: '#232F3E',
            50: '#232F3E',
            100: '#232F3E',
            200: '#232F3E',
            300: '#232F3E',
            400: '#232F3E',
            500: '#232F3E',
            600: '#232F3E',
            700: '#232F3E',
            800: '#232F3E',
            900: '#232F3E',
            950: '#232F3E'
        },
        success: {
            25: '#F6FEF9',
            50: '#ECFDF3',
            100: '#DCFAE6',
            200: '#ABEFC6',
            300: '#75E0A7',
            400: '#47CD89',
            500: '#17B26A',
            600: '#079455',
            700: '#067647',
            800: '#085D3A',
            900: '#074D31',
            950: '#053321'
        },
        danger: {
            25: '#FFFBFA',
            50: '#FEF3F2',
            100: '#FEE4E2',
            200: '#FECDCA',
            300: '#FDA29B',
            400: '#F97066',
            500: '#F04438',
            600: '#D92D20',
            700: '#B42318',
            800: '#912018',
            900: '#7A271A',
            950: '#55160C'
        },
        warning: {
            25: '#FFFCF5',
            50: '#FFFAEB',
            100: '#FEF0C7',
            200: '#FEDF89',
            300: '#FEC84B',
            400: '#FDB022',
            500: '#F79009',
            600: '#DC6803',
            700: '#B54708',
            800: '#93370D',
            900: '#7A2E0E',
            950: '#4E1D09'
        }
    }
    //todo: we will support the dark mode documenting this one and adding the dark types to our style.d.ts
    /*dark: null*/
}
