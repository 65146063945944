import {SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useTranslation} from 'react-i18next'
import {z} from 'zod'
import Button from '@components/ui/Button'
import TextArea from '@components/commons/textarea/Textarea.tsx'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'
import {Radio} from '@components/commons/radio/Radio.tsx'
import {useAsync} from '@hooks/useAsync.ts'
import toast from 'react-hot-toast'
import {httpStoreProject, HttpStoreProjectPayload} from '@/features/project/services/project.http.ts'
import {useNavigate} from 'react-router-dom'
import {Spinner} from '@components/ui/Spinner/Spinner.tsx'
import {StyledGenerationForm} from '@/features/project/components/generation-form/style.ts'
import InputHelpText from '@components/ui/InputHelpText'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {breakpoints} from '@/theme/mediaQueries.ts'
import {Duration, Seniority} from '@/features/project/types.ts'
import {useTheme} from 'styled-components'
import {durationToMinutes} from '@utilities/helpers.ts'
import {ROUTES_PATHNAMES} from '@utilities/constants'

const ValidationSchema = z.object({
    knowledge: z.enum(Seniority.options, {
        required_error: 'errors:required_knowledge',
        invalid_type_error: 'errors:required_knowledge'
    }),
    duration: z.enum(Duration.options, {
        required_error: 'errors:required_duration',
        invalid_type_error: 'errors:required_duration'
    }),
    topic: z.string().min(1, {message: 'errors:required_topic'}).max(400, {message: 'errors:too_long_topic'})
})
type ValidationSchema = z.infer<typeof ValidationSchema>

const GenerationForm = () => {
    const {t} = useTranslation()
    const {width: viewportWidth} = useWindowDimensions()
    const theme = useTheme()
    const navigate = useNavigate()
    const {
        handleSubmit,
        register,
        formState: {errors}
    } = useForm<ValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(ValidationSchema)
    })
    const storeProject = useAsync()

    const onSubmit: SubmitHandler<ValidationSchema> = async formData => {
        const payload = {
            mediaTypes: ['video'],
            minutes: durationToMinutes(formData.duration),
            prompt: formData.topic,
            seniority: formData.knowledge
        } satisfies HttpStoreProjectPayload

        try {
            const response = await storeProject.run(httpStoreProject(payload))
            const job = response.jobs[0]

            navigate(
                ROUTES_PATHNAMES.PROJECT.replace(':projectId', String(response.id)).replace(':jobId', String(job.id))
            )
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <StyledGenerationForm>
            <Flexbox as="form" direction="column" gap={8} onSubmit={handleSubmit(onSubmit)}>
                <Flexbox width="100%" direction="column" gap={6}>
                    <Flexbox width="100%" direction="column" gap={3}>
                        <h3>{t('generation:form:topic_label')}</h3>
                        <TextArea
                            width="100%"
                            placeholder={t('generation:form:topic_placeholder').toString()}
                            maxLength={400}
                            rows={6}
                            errorMessage={errors.topic?.message ? t(errors.topic?.message) : null}
                            {...register('topic')}
                        />
                    </Flexbox>
                    <Flexbox width="100%" direction="column" gap={3}>
                        <h3>{t('generation:form:knowledge_label')}</h3>
                        <Flexbox direction="column" gap={2}>
                            <Radio
                                label={t('commons:seniority:beginner')}
                                value="beginner"
                                {...register('knowledge')}
                            />
                            <Radio
                                label={t('commons:seniority:middle')}
                                value="intermediate"
                                {...register('knowledge')}
                            />
                            <Radio label={t('commons:seniority:senior')} value="advanced" {...register('knowledge')} />
                            {errors.knowledge?.message ? <InputHelpText error={t(errors.knowledge?.message)} /> : null}
                        </Flexbox>
                    </Flexbox>
                    <Flexbox width="100%" direction="column" gap={3}>
                        <h3>{t('generation:form:duration_label')}</h3>
                        <Flexbox direction="column" gap={2}>
                            <Radio
                                label={t('generation:form:duration_options_1')}
                                value="short"
                                {...register('duration')}
                            />
                            <Radio
                                label={t('generation:form:duration_options_2')}
                                value="medium"
                                {...register('duration')}
                            />
                            <Radio
                                label={t('generation:form:duration_options_3')}
                                value="long"
                                {...register('duration')}
                            />
                            {errors.duration?.message ? <InputHelpText error={t(errors.duration?.message)} /> : null}
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                <Button
                    type="submit"
                    disabled={storeProject.isLoading}
                    fullWidth={viewportWidth <= breakpoints.width.s}
                >
                    {t('generation:form:button_label')}
                    {storeProject.isLoading ? (
                        <Spinner bgColor="transparent" borderColor={theme.palette.neutral.white} size={16} />
                    ) : null}
                </Button>
            </Flexbox>
        </StyledGenerationForm>
    )
}

export default GenerationForm
