import axios from 'axios'
import {envVars} from '@/envVars.ts'
import {z} from 'zod'
import {Language} from '@/features/languages/types.ts'

const HttpGetLanguagesResponse = z.array(Language)
export const httpGetLanguages = () => {
    return axios
        .get(`${envVars.VITE_API_ENDPOINT}languages`)
        .then(response => HttpGetLanguagesResponse.parse(response.data))
}
