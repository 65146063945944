export const breakpoints = {
    width: {
        xxs: 365,
        xs: 414,
        s: 768,
        m: 960,
        l: 1200,
        xl: 1440,
        xxl: 1920
    },
    height: {
        s: 620,
        m: 680,
        l: 800
    }
}

const mediaQueries = {
    xxs: `@media only screen and (max-width: ${breakpoints.width.xxs}px)`,
    xs: `@media only screen and (max-width: ${breakpoints.width.xs}px)`,
    s: `@media only screen and (max-width: ${breakpoints.width.s}px)`,
    m: `@media only screen and (max-width: ${breakpoints.width.m}px)`,
    l: `@media only screen and (max-width: ${breakpoints.width.l}px)`,
    xl: `@media only screen and (max-width: ${breakpoints.width.xl}px)`,

    hs: `@media only screen and (max-height: ${breakpoints.height.s}px)`,
    hm: `@media only screen and (max-height: ${breakpoints.height.m}px)`,
    hl: `@media only screen and (max-height: ${breakpoints.height.l}px)`
}

export default mediaQueries
