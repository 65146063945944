import {CSSProperties, forwardRef, ReactNode, TextareaHTMLAttributes, useState} from 'react'
import Label from '@components/ui/Label'
import InputHelpText from '@components/ui/InputHelpText'
import {DefaultTFuncReturn} from 'i18next'
import {
    StyledMaxLength,
    StyledTextarea,
    StyledTextareaContainer,
    StyledTextareaGrid,
    StyledTextareaWrapper
} from '@components/commons/textarea/style.ts'

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    className?: string
    label?: string | DefaultTFuncReturn
    name?: string
    typeIcon?: ReactNode
    touched?: boolean
    errorMessage?: string | DefaultTFuncReturn
    helpText?: string | DefaultTFuncReturn
    placeholder?: string
    width?: CSSProperties['width']
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            label,
            name,
            typeIcon,
            touched = false,
            errorMessage,
            helpText,
            className,
            disabled,
            placeholder,
            width,
            onChange,
            maxLength,
            ...rest
        },
        ref
    ) => {
        const [textLength, setTextLength] = useState(0)

        return (
            <StyledTextareaContainer className={className} width={width}>
                {label && <Label htmlFor={name}>{label}</Label>}
                <StyledTextareaGrid>
                    <StyledTextareaWrapper touched={touched} hasError={!!errorMessage} disabled={disabled}>
                        {typeIcon}
                        <StyledTextarea
                            ref={ref}
                            name={name}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            disabled={disabled}
                            onChange={event => {
                                setTextLength(event.target.value.length)
                                onChange?.(event)
                            }}
                            {...rest}
                        />
                        {maxLength ? (
                            <StyledMaxLength>
                                {textLength}
                                {'/'}
                                {maxLength}
                            </StyledMaxLength>
                        ) : null}
                    </StyledTextareaWrapper>
                </StyledTextareaGrid>
                <InputHelpText error={errorMessage} helpText={helpText} />
            </StyledTextareaContainer>
        )
    }
)

export default TextArea

TextArea.displayName = 'TextArea'
