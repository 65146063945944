import styled, {css} from 'styled-components'

export const StyledGeneratedContentSectionTitle = styled.h3(
    ({theme: {spacing, typography, palette}}) => css`
        width: 100%;
        padding-bottom: ${spacing * 5}px;
        font-size: ${typography.textLg};
        font-weight: 700;
        color: ${palette.neutral['900']};
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)
