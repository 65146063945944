import {z} from 'zod'
import {Language} from '@/features/languages/types.ts'

export const MediaType = z.enum(['video', 'text', 'image'])
export type MediaType = z.infer<typeof MediaType>

export const MediaStatus = z.enum([
    'initialized',
    'promptTranslation',
    'textGeneration',
    'translationToSourceLanguage',
    'audioGeneration',
    'lipSync',
    'completed',
    'failed'
])
export type MediaStatus = z.infer<typeof MediaStatus>

export const TextMetadata = z.object({})
export type TextMetadata = z.infer<typeof TextMetadata>

export const ImageMetadata = z.object({})
export type ImageMetadata = z.infer<typeof ImageMetadata>

export const VideoMetadata = z.object({
    audioUrl: z.string(),
    generatedText: z.string(),
    generatedTitle: z.string(),
    generationPrompt: z.string(),
    videoUrl: z.string()
})
export type VideoMetadata = z.infer<typeof VideoMetadata>

export const Duration = z.enum(['short', 'medium', 'long'])
export type Duration = z.infer<typeof Duration>

export const Seniority = z.enum(['beginner', 'intermediate', 'advanced'])
export type Seniority = z.infer<typeof Seniority>

export const Media = z.object({
    createdAt: z.string(),
    id: z.string().min(1),
    jobId: z.string().min(1),
    metadata: VideoMetadata.partial(),
    password: z.string().min(1),
    status: MediaStatus,
    type: MediaType
})
export type Media = z.infer<typeof Media>

export const Job = z.object({
    createdAt: z.string(),
    id: z.string().min(1),
    medias: z.array(Media),
    projectId: z.string().min(1),
    targetLanguage: Language,
    title: z.string().nullable(),
    translatedPrompt: z.string().nullable()
})
export type Job = z.infer<typeof Job>

export const Project = z.object({
    createdAt: z.string(),
    id: z.string().min(1),
    jobs: z.array(Job),
    minutes: z.number().int().min(1),
    prompt: z.string().min(1),
    seniority: Seniority,
    sourceLanguage: Language
})
export type Project = z.infer<typeof Project>
