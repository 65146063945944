import Container from '@components/ui/Container'
import {StyledHeader} from '@components/commons/header/style.ts'
import Logo from '@assets/images/logo-light.svg'
import {LanguageButton} from '@/features/languages/components/language-button/LanguageButton.tsx'
import {Link, useMatch} from 'react-router-dom'
import {ROUTES_PATHNAMES} from '@utilities/constants'

export const Header = () => {
    const projectPage = useMatch(ROUTES_PATHNAMES.PROJECT)

    return (
        <StyledHeader>
            <Container className="header-container">
                <Link to="/">
                    <img src={Logo} alt="logo" />
                </Link>
                {!!projectPage && <LanguageButton />}
            </Container>
        </StyledHeader>
    )
}

Header.displayName = 'Header'
