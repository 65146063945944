import Generation from '@pages/generation/Generation.tsx'
import {Project} from '@pages/project/Project.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon} from '@components/ui/Icon'
import i18next from 'i18next'
import {NotFound} from '@pages/not-found/NotFound.tsx'
import {ProjectRouteGuard} from '@route-guards/ProjectRouteGuard.tsx'

export const ROUTES_PATHNAMES = {
    GENERATION: '/',
    PROJECT: '/projects/:projectId/jobs/:jobId'
} as const

export const routes = {
    GENERATION: {
        path: ROUTES_PATHNAMES.GENERATION,
        element: <Generation />,
        errorElement: <ErrorBox icon={<AlertCircleIcon />} title={i18next.t('errors:default')} />
    },
    PROJECT: {
        element: <ProjectRouteGuard />,
        children: [
            {
                element: <Project />,
                path: ROUTES_PATHNAMES.PROJECT,
                errorElement: <ErrorBox icon={<AlertCircleIcon />} title={i18next.t('errors:default')} />
            }
        ]
    },
    NOT_FOUND: {
        path: '*',
        element: <NotFound />,
        errorElement: <ErrorBox icon={<AlertCircleIcon />} title={i18next.t('errors:default')} />
    }
}
