import Button from '@components/ui/Button'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'
import {CheckCircleBrokenIcon} from '@components/ui/Icon'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
    StyledColumns,
    StyledGeneratedContentView,
    StyledHeading
} from '@/features/project/components/generated-content-view/style.ts'
import {Helmet} from 'react-helmet'
import {FC} from 'react'
import {Job, Project} from '@/features/project/types.ts'
import {GeneratedDetails} from '@/features/project/components/generated-details/GeneratedDetails.tsx'
import {GeneratedContentSectionTitle} from '@/features/project/components/generated-content-section-title/GeneratedContentSectionTitle.tsx'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {breakpoints} from '@/theme/mediaQueries.ts'
import {ROUTES_PATHNAMES} from '@utilities/constants'

export const GeneratedContentView: FC<{project: Project; job: Job; minutesLeft: number | null}> = ({
    project,
    job,
    minutesLeft
}) => {
    const {t} = useTranslation()
    const {width: viewportWidth} = useWindowDimensions()
    const navigate = useNavigate()
    const isViewportM = viewportWidth <= breakpoints.width.s

    return (
        <StyledGeneratedContentView>
            <Helmet title={String(t('project:helmet_generated_title'))} />
            <StyledHeading
                direction={isViewportM ? 'column' : 'row'}
                justify="space-between"
                align={isViewportM ? 'flex-start' : 'flex-end'}
                gap={6}
            >
                <Flexbox direction="column" gap={4}>
                    <Flexbox gap={2} align="center" justify="center" className="success-title">
                        <CheckCircleBrokenIcon size={24} />
                        <span>{t('project:success_title')}</span>
                    </Flexbox>
                    <h2>{job.title}</h2>
                </Flexbox>
                <Button onClick={() => navigate(ROUTES_PATHNAMES.GENERATION)}>{t('project:cta_label')}</Button>
            </StyledHeading>
            <StyledColumns>
                <GeneratedDetails project={project} job={job} minutesLeft={minutesLeft} />
                <div className="content">
                    <GeneratedContentSectionTitle>{t('project:content')}</GeneratedContentSectionTitle>
                    <div className="content-text">
                        {job.medias[0].metadata.generatedText
                            ?.split('\n')
                            .map((paragraph, index) =>
                                paragraph === '' ? <br key={index} /> : <p key={index}>{paragraph}</p>
                            )}
                    </div>
                </div>
            </StyledColumns>
        </StyledGeneratedContentView>
    )
}

GeneratedContentView.displayName = 'GeneratedContentView'
