import styled, {css} from 'styled-components'
import Container from '@components/ui/Container'

export const StyledGeneratingLoadingView = styled(Container)(
    ({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 8}px;
        text-align: center;

        h2 {
            font-size: ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }
        p {
            font-size: ${typography.textSm};
            color: ${palette.neutral['500']};
        }
    `
)
