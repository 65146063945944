import styled, {css} from 'styled-components'
import {spacing} from '@/theme'

export const StyledGenerationHero = styled.section(
    ({theme: {typography, palette}}) => css`
        background: ${palette.secondary['600']};
        color: ${palette.neutral.white};

        .hero-container {
            padding-top: ${spacing * 17.5}px;
            padding-bottom: ${spacing * 17.5}px;

            h1 {
                font-size: ${typography.displaySm};
                font-weight: 700;
            }
            p {
                font-size: ${typography.textLg};
            }
        }
    `
)
