import Logo from '@assets/images/logo-dark.svg'
import TranslatedLogo from '@assets/images/translated-logo.svg'
import {StyledFooter} from '@components/commons/footer/style.ts'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'
import Container from '@components/ui/Container'

export const Footer = () => {
    const {t} = useTranslation()

    return (
        <StyledFooter>
            <Container className="footer-container">
                <img className="logo" src={Logo} alt="logo" />
                <Flexbox direction="column" gap={1}>
                    <span>{t('commons:powered_by')}</span>
                    <img className="translated-logo" src={TranslatedLogo} alt="translated-logo" />
                </Flexbox>
            </Container>
        </StyledFooter>
    )
}

Footer.displayName = 'Footer'
