import {forwardRef, InputHTMLAttributes} from 'react'
import {DefaultTFuncReturn} from 'i18next'
import InputHelpText from '@components/ui/InputHelpText'
import {StyledRadio, StyledRadioInputWrapper} from '@components/commons/radio/style.ts'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
    id?: string
    label?: string | DefaultTFuncReturn
    labelPosition?: 'left' | 'right'
    name?: string
    errorMessage?: string | DefaultTFuncReturn
    helpText?: string | DefaultTFuncReturn
    placeholder?: string
    inputSize?: 'sm' | 'md' | 'lg'
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
    ({label, labelPosition = 'right', helpText, name, onChange, onBlur, ...rest}, ref) => {
        return (
            <Flexbox direction="column" gap={2}>
                <StyledRadioInputWrapper labelPosition={labelPosition}>
                    <StyledRadio type="radio" name={name} onChange={onChange} onBlur={onBlur} {...rest} ref={ref} />
                    <span>{label}</span>
                </StyledRadioInputWrapper>
                <InputHelpText helpText={helpText} />
            </Flexbox>
        )
    }
)

Radio.displayName = 'Radio'
