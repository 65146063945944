import {useTranslation} from 'react-i18next'
import {StyledGenerationHero} from '@/features/project/components/generation-hero/style.ts'
import Container from '@components/ui/Container'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'

export const GenerationHero = () => {
    const {t} = useTranslation()

    return (
        <StyledGenerationHero>
            <Container className="hero-container">
                <Flexbox direction="column" gap={4}>
                    <h1>{t('AWS Training and Certification')}</h1>
                    <p>{t('generation:hero_subtitle')}</p>
                </Flexbox>
            </Container>
        </StyledGenerationHero>
    )
}
