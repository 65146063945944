import styled, {css} from 'styled-components'
import mediaQueries from '@/theme/mediaQueries.ts'

export const StyledGeneration = styled.div`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
`
export const StyledGenerationContent = styled.div(
    ({bgImage}: {bgImage: string}) => css`
        position: relative;
        .generation-container {
            display: grid;
            grid-template-columns: minmax(700px, 1fr) 1fr;
        }
        .generation-background {
            background-image: url(${bgImage});
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top left;
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            width: 40%;
        }

        ${mediaQueries.m} {
            .generation-container {
                grid-template-columns: 1fr 0;
            }
            .generation-background {
                display: none;
            }
        }
    `
)
