import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetProject} from '@/features/project/services/project.http.ts'
import {Project} from '@/features/project/types.ts'

export const useProject = (
    projectId?: string,
    jobId?: string,
    options?: Omit<UseQueryOptions<Project | undefined>, 'queryKey' | 'queryFn'> & {
        onSuccessQueryFn?: (response: Project) => void
    }
) => {
    return useQuery<Project | undefined>({
        ...options,
        queryKey: [QUERY_KEYS.PROJECT, projectId, jobId],
        queryFn: async () => {
            if (!projectId) {
                return
            }
            const response = await httpGetProject(projectId)
            options?.onSuccessQueryFn?.(response)
            return response
        }
    })
}
