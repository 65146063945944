import styled, {css} from 'styled-components'
import Container from '@components/ui/Container'

export const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledErrorWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        gap: ${spacing * 4}px;
        max-width: 1024px;

        & h1 {
            ${typography.displayMd};
            font-weight: 600;
            color: ${palette.neutral['900']};
            text-align: center;
        }
    `}
`
