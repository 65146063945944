import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetLanguages} from '@/features/languages/services/languages.http.ts'

export const useLanguages = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.LANGUAGES],
        queryFn: httpGetLanguages
    })
}
