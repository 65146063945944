import {Outlet} from 'react-router-dom'
import React from 'react'
import {Header} from '@components/commons/header/Header.tsx'
import {StyledPublicLayout} from '@layouts/public-layout/style.ts'
import {Footer} from '@components/commons/footer/Footer.tsx'

export const PublicLayout: React.FC = () => {
    return (
        <StyledPublicLayout>
            <Header />
            <div className="page-content">
                <Outlet />
                <Footer />
            </div>
        </StyledPublicLayout>
    )
}
