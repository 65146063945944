import styled, {css, DefaultTheme} from 'styled-components'
import {CSSProperties} from 'react'

type StyledTextareaProps = {
    touched: boolean
    hasError: boolean
    disabled?: boolean
}

export const StyledTextareaContainer = styled.div`
    ${({width, theme: {spacing}}: {theme: DefaultTheme; width: CSSProperties['width']}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
        min-height: 92px;
        width: ${width};
    `}
`

export const StyledTextareaGrid = styled.div`
    ${() => css`
        display: flex;
        flex-direction: row;
        align-items: center;
    `}
`

export const StyledTextareaWrapper = styled.div<StyledTextareaProps>`
    ${({disabled, hasError, theme: {spacing, palette, shadows}}) => css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 8px;
        padding: ${spacing * 3}px ${spacing * 3.5}px;
        width: 100%;
        transition: ease-in-out 200ms;
        cursor: pointer;

        ${hasError &&
        css`
            border: 1px solid ${palette.danger['300']};
        `};

        ${disabled &&
        css`
            background: ${palette.neutral['100']};
            color: ${palette.neutral['500']};
            cursor: not-allowed;
        `};

        ${!disabled &&
        css`
            &:not(:disabled):hover {
                border: 1px solid ${hasError ? palette.danger['300'] : palette.primary['300']};
                box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${
                    hasError ? palette.danger['100'] : palette.primary['100']
                }`};
            }
        `}
    `}
`

export const StyledTextarea = styled.textarea`
    ${({theme: {typography, palette}}) => css`
        flex: 1;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: ${palette.neutral['900']};
        ${typography.textMd}

        &:focus {
            outline: none;
        }
        &::placeholder {
            color: ${palette.neutral['500']};
        }
        &:disabled {
            cursor: not-allowed;
        }
    `}
`

export const StyledMaxLength = styled.span(
    ({theme: {spacing, typography, palette}}) => css`
        position: absolute;
        bottom: ${spacing * 2.5}px;
        right: ${spacing * 3}px;
        font-size: ${typography.textXs};
        color: ${palette.neutral['600']};
    `
)
