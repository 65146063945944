import styled, {css} from 'styled-components'

export const StyledLabel = styled.label`
    ${({theme: {palette, typography}}) => css`
        display: block;
        color: ${palette.neutral['700']};
        font-weight: 500;
        text-align: start;
        ${typography.textSm};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
`
