import {FC, ReactNode} from 'react'
import {StyledCloseIcon, StyledModal, StyledOverlay} from '@components/ui/Modal/style.ts'

interface ModalProps {
    children: ReactNode
    className?: string
    closeIconOffset?: 'sm' | 'md' | 'lg'
    onClose?: () => void
    width?: number
}

const Modal: FC<ModalProps> = ({children, className, closeIconOffset = 'md', onClose, width}) => {
    return (
        <>
            <StyledOverlay onClick={onClose} />
            <StyledModal width={width} className={className}>
                {onClose && <StyledCloseIcon $closeIconOffset={closeIconOffset} onClick={onClose} />}
                {children}
            </StyledModal>
        </>
    )
}

Modal.displayName = 'Modal'
export default Modal
