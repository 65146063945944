import {createBrowserRouter} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'

const router = createBrowserRouter(
    [
        {
            element: <PublicLayout />,
            children: [routes.GENERATION, routes.NOT_FOUND, routes.PROJECT]
        }
    ],
    {
        basename: '/'
    }
)

export default router
