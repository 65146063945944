import {GeneratedContentView} from '@/features/project/components/generated-content-view/GeneratedContentView.tsx'
import {GeneratingLoadingView} from '@/features/project/components/generating-loading-view/GeneratingLoadingView.tsx'
import {Spinner} from '@components/ui/Spinner/Spinner.tsx'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'
import {useOutletContext} from 'react-router-dom'
import {useProject} from '@/features/project/queries/useProject.ts'
import {ProjectPageParams} from '@route-guards/ProjectRouteGuard.tsx'
import {AlertCircleIcon} from '@components/ui/Icon'
import i18next from 'i18next'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {durationToMinutesLeft, findCurrentJob, minutesToDuration} from '@utilities/helpers.ts'
import {useState} from 'react'
import dayjs from '@utilities/dayjs.ts'
import {Media} from '@/features/project/types.ts'

const loadingStatuses = [
    ...([
        'initialized',
        'promptTranslation',
        'textGeneration',
        'translationToSourceLanguage'
    ] as const satisfies ReadonlyArray<Partial<Media['status']>>)
] as string[] //needed to do the includes check

export const Project = () => {
    const {urlParams} = useOutletContext<{urlParams: ProjectPageParams}>()
    const [minutesLeft, setMinutesLeft] = useState<number | null>(null)
    const projectQuery = useProject(urlParams.projectId, urlParams.jobId, {
        refetchInterval: data => {
            const job = findCurrentJob(data, urlParams.jobId)
            return job && job.medias[0].status != 'completed' && job.medias[0].status != 'failed' ? 2000 : false
        },
        onSuccessQueryFn: data => {
            const media = findCurrentJob(data, urlParams.jobId)?.medias[0]

            if (
                media &&
                media.status != 'completed' &&
                media.status != 'failed' &&
                (minutesLeft == null || minutesLeft >= 1)
            ) {
                const endDate = dayjs(media.createdAt).add(
                    durationToMinutesLeft[minutesToDuration(data.minutes)],
                    'minutes'
                )
                setMinutesLeft(dayjs(endDate).diff(dayjs(), 'minutes'))
            }
        }
    })
    const job = findCurrentJob(projectQuery.data, urlParams.jobId)

    /*    useEffect(() => {
        if (job && job?.targetLanguage.iso_639_1 != i18n.language) {
            changeAppLanguage(job.targetLanguage.iso_639_1)
        }
    }, [job?.targetLanguage])*/

    if (projectQuery.isLoading && !projectQuery.data) {
        return (
            <Flexbox height="100%" justify="center" align="center">
                <Spinner size={40} />
            </Flexbox>
        )
    }
    if (projectQuery.isError || !job || job.medias[0].status == 'failed' || !projectQuery.data) {
        return (
            <ErrorBox
                icon={<AlertCircleIcon />}
                title={i18next.t(job?.medias[0].status == 'failed' ? 'errors:default' : 'project:invalid_link')}
            />
        )
    }
    if (loadingStatuses.includes(job.medias[0].status)) {
        return <GeneratingLoadingView />
    }
    return <GeneratedContentView project={projectQuery.data} job={job} minutesLeft={minutesLeft} />
}

Project.displayName = 'Project'
