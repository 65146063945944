import {Globe02Icon} from '@components/ui/Icon'
import {APP_LANGUAGES} from '@/translations/i18n.ts'
import Button from '@components/ui/Button'
import {useState} from 'react'
import {LanguageModal} from '@/features/languages/components/language-modal/LanguageModal.tsx'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {breakpoints} from '@/theme/mediaQueries.ts'
import {useMatch} from 'react-router-dom'
import {ROUTES_PATHNAMES} from '@utilities/constants'
import {useProject} from '@/features/project/queries/useProject.ts'
import {findCurrentJob} from '@utilities/helpers.ts'

export const LanguageButton = () => {
    const {width: viewportWidth} = useWindowDimensions()
    const [showModal, setShowModal] = useState(false)
    const projectPage = useMatch(ROUTES_PATHNAMES.PROJECT)
    const project = useProject(projectPage?.params.projectId, projectPage?.params.jobId, {
        enabled: !!projectPage?.params.projectId && !!projectPage.params.jobId
    })
    const job = projectPage?.params.jobId ? findCurrentJob(project.data, projectPage.params.jobId) : undefined

    return (
        <>
            <Button variant="whiteGhost" onClick={() => setShowModal(true)}>
                <Globe02Icon size={20} />
                {viewportWidth > breakpoints.width.xs
                    ? APP_LANGUAGES.find(appLanguage => appLanguage.value == job?.targetLanguage.iso_639_1)?.label
                    : null}
            </Button>
            {showModal ? (
                <LanguageModal language={String(job?.targetLanguage.iso_639_1)} onClose={() => setShowModal(false)} />
            ) : null}
        </>
    )
}
