import {useTranslation} from 'react-i18next'
import {StyledGeneratingLoadingView} from '@/features/project/components/generating-loading-view/style.ts'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'
import {Spinner} from '@components/ui/Spinner/Spinner.tsx'
import {Helmet} from '@components/ui/helmet/Helmet.tsx'

export const GeneratingLoadingView = () => {
    const {t} = useTranslation()

    return (
        <StyledGeneratingLoadingView>
            <Helmet title={String(t('project:helmet_loading_title'))} />
            <Spinner size={48} borderWidth={6} />
            <Flexbox direction="column" gap={3} align="center">
                <h2>{t('project:loading_title')}</h2>
                <p>{t('project:loading_subtitle')}</p>
            </Flexbox>
        </StyledGeneratingLoadingView>
    )
}

GeneratingLoadingView.displayName = 'GeneratingLoadingView'
