import styled from 'styled-components'

export const StyledPublicLayout = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    overflow-y: hidden;

    .page-content {
        height: 100%;
        display: grid;
        grid-template-rows: 1fr auto;
        overflow-y: auto;
    }
`
