import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './translations/i18n.ts'
import {QueryClientProvider} from '@tanstack/react-query'
import {queryClient} from '@/queryClient.ts'
import {ErrorBoundary} from '@/ErrorBoundary.tsx'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </ErrorBoundary>
    </React.StrictMode>
)
