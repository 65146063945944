import {StyledButton} from './style'
import {ButtonHTMLAttributes, forwardRef, ReactNode} from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    children: ReactNode
    fullWidth?: boolean
    size?: 'md'
    variant?: 'primary' | 'whiteGhost'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {children, className, type = 'button', disabled, fullWidth = false, size = 'md', variant = 'primary', ...rest},
        ref
    ) => (
        <StyledButton
            ref={ref}
            className={className}
            type={type}
            disabled={disabled}
            fullWidth={fullWidth}
            size={size}
            variant={variant}
            {...rest}
        >
            {children}
        </StyledButton>
    )
)

export default Button

Button.displayName = 'Button'
