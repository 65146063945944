import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme'
import router from './router.tsx'
import {Toaster} from 'react-hot-toast'

function App() {
    /*    const {i18n} = useTranslation()

    useEffect(() => {
        document.body.dir = i18n.dir(i18n.language)
    }, [i18n.language])*/

    return (
        <ThemeProvider theme={getAppTheme({name: 'light'})}>
            <StyleSheetManager>
                <>
                    <Toaster />
                    <GlobalStyles />
                    <RouterProvider router={router} />
                </>
            </StyleSheetManager>
        </ThemeProvider>
    )
}

export default App
