import {createGlobalStyle, withTheme} from 'styled-components'
import {palette} from '@/theme/palette.ts'
import AmazonEmberLight2 from '../assets/font/AmazonEmber_W_Lt.woff2'
import AmazonEmberLight from '../assets/font/AmazonEmber_W_Lt.woff'
import AmazonEmberRegular2 from '../assets/font/AmazonEmber_W_Rg.woff2'
import AmazonEmberRegular from '../assets/font/AmazonEmber_W_Rg.woff'
import AmazonEmberSemibold2 from '../assets/font/AmazonEmber_W_SBd.woff2'
import AmazonEmberSemibold from '../assets/font/AmazonEmber_W_SBd.woff'
import AmazonEmberBold2 from '../assets/font/AmazonEmber_W_Bd.woff2'
import AmazonEmberBold from '../assets/font/AmazonEmber_W_Bd.woff'

const ResetStyles = createGlobalStyle`
  @font-face {
    font-family: 'AmazonEmber';
    font-weight: 300;
    src: url("${AmazonEmberLight2}") format("woff2"), url("${AmazonEmberLight}") format("woff")
  }

  @font-face {
    font-family: 'AmazonEmber';
    font-weight: 400;
    src: url("${AmazonEmberRegular2}") format("woff2"), url("${AmazonEmberRegular}") format("woff")
  }

  @font-face {
    font-family: 'AmazonEmber';
    font-weight: 500;
    src: url("${AmazonEmberSemibold2}") format("woff2"), url("${AmazonEmberSemibold}") format("woff")
  }

  @font-face {
    font-family: 'AmazonEmber';
    font-weight: 600;
    src: url("${AmazonEmberSemibold2}") format("woff2"), url("${AmazonEmberSemibold}") format("woff")
  }

  @font-face {
    font-family: 'AmazonEmber';
    font-weight: 700;
    src: url("${AmazonEmberBold2}") format("woff2"), url("${AmazonEmberBold}") format("woff")
  }

  * {
  '-ms-overflow-style': 'none' /* for Internet Explorer, Edge */,
  ' scrollbar-width': 'none' /* for Firefox */,
  }

  html {
    height: 100%;
    font-size: 16px;
    font-family: 'AmazonEmber', sans-serif;
    color: ${palette.light.neutral[700]};
    background-color: ${palette.light.neutral.white};
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  }

  body {
    height: 100%;
  }

  #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, span, small {
    margin-bottom: 0;
  }

  label, input, textarea, select, button {
    font-family: inherit;
  }

  textarea {
    overflow-wrap: break-word;
  }
  
  a {
    cursor: pointer;
  }

  //Reset Css

  /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
  *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
    all: unset;
    display: revert;
  }

  /* Preferred box-sizing value */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Remove list styles (bullets/numbers) */
  ol, ul {
    list-style: none;
  }

  /* For images to not be able to exceed their container */
  img {
    max-width: 100%;
  }

  /* Removes spacing between cells in tables */
  table {
    border-collapse: collapse;
  }

  /* Revert the 'white-space' property for textarea elements on Safari */
  textarea {
    white-space: revert;
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`

export default withTheme(ResetStyles)
