import styled, {css, DefaultTheme} from 'styled-components'

type StyledBtnProps = {
    fullWidth: boolean
    size: 'md'
    variant: 'primary' | 'whiteGhost'
}
export const StyledButton = styled.button<StyledBtnProps>`
    ${({fullWidth, theme: {spacing}}) => css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
        border: none;
        border-radius: 8px;
        width: ${fullWidth ? '100%' : 'auto'};
        transition: ease-in-out 200ms;
        cursor: pointer;
        font-weight: 600;
        & svg {
            width: 20px;
        }
    `}
    ${({variant, theme}) => ColorStyles(theme)[variant]};
    ${({size, theme}) => SizeStyles(theme)[size]};
`

const ColorStyles = ({palette, shadows}: DefaultTheme) => ({
    primary: css`
        color: ${palette.neutral.white};
        background: ${palette.primary['600']};
        border: 1px solid ${palette.primary['600']};
        box-shadow: ${shadows.xs};
        &:not(:disabled):hover {
            background: ${palette.primary['700']};
            border: 1px solid ${palette.primary['700']};
        }
        &:not(:disabled):focus {
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
        &:disabled {
            background: ${palette.primary['200']};
            border: 1px solid ${palette.primary['200']};
            cursor: not-allowed;
        }
    `,
    whiteGhost: css`
        color: ${palette.neutral.white};
        background: transparent;
        &:not(:disabled):hover {
            background: ${palette.neutral.white}0D;
        }
        &:disabled {
            cursor: not-allowed;
        }
    `
})

const SizeStyles = ({spacing, typography}: DefaultTheme) => ({
    md: css`
        height: 40px;
        padding: 0 ${spacing * 3.5}px;
        ${typography.textSm}
    `
})
