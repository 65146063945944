import styled, {css} from 'styled-components'

export const StyledRadioInputWrapper = styled.label<{labelPosition: 'left' | 'right'}>`
    ${({theme: {spacing, typography, palette}, labelPosition}) => css`
        display: flex;
        flex-direction: ${labelPosition === 'right' ? 'row' : 'row-reverse'};
        max-width: 100%;
        gap: ${spacing * 4}px;
        cursor: pointer;
        font-size: ${typography.textMd};
        color: ${palette.neutral['700']};
    `}
`

export const StyledRadio = styled.input`
    ${({theme: {palette, shadows, spacing}}) => css`
        flex: none;
        background-color: ${palette.neutral.white};
        width: 20px;
        height: 20px;
        padding: ${spacing}px;
        margin: ${spacing * 0.5}px 0;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${palette.neutral['300']};
        transition: ease-in-out 300ms;

        &:checked {
            background: ${palette.primary['600']};
            border: 1px solid ${palette.primary['600']};
            content: ${`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3' viewBox='0 0 6 6' fill='none'%3E%3Ccircle cx='3' cy='3' r='3' fill='${palette.neutral[
                'white'
            ].replace('#', '%23')}'/%3E%3C/svg%3E");`};
        }

        &:disabled:checked {
            background: ${palette.neutral['100']};
            border: 1px solid ${palette.neutral['300']};
            cursor: not-allowed;
            content: ${`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='3' viewBox='0 0 6 6' fill='none'%3E%3Ccircle cx='3' cy='3' r='3' fill='${palette.neutral[
                '300'
            ].replace('#', '%23')}'/%3E%3C/svg%3E");`};
        }

        &:disabled {
            background: ${palette.neutral['100']};
            border: 1px solid ${palette.neutral['300']};
            cursor: not-allowed;
        }

        &:not(:disabled):hover {
            border: 1px solid ${palette.primary['300']};
            box-shadow: ${`${shadows.xs}, 0px 0px 0px 2px ${palette.primary['100']}`};
        }
    `}
`
