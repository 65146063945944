import styled, {css} from 'styled-components'

export const StyledHeader = styled.header`
    ${({theme: {palette, spacing}}) => css`
        background: ${palette.secondary['600']};
        border-bottom: 1px solid ${palette.neutral['600']};
        padding: ${spacing * 3}px;
        .header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    `}
`
