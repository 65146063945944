import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'
import {InfoCircleIcon} from '@components/ui/Icon'
import {minutesToDuration} from '@utilities/helpers.ts'
import {StyledDetails, StyledVideoWrapper} from '@/features/project/components/generated-details/style.ts'
import {useTranslation} from 'react-i18next'
import {Duration, Job, Project, Seniority} from '@/features/project/types.ts'
import {FC} from 'react'
import {GeneratedContentSectionTitle} from '@/features/project/components/generated-content-section-title/GeneratedContentSectionTitle.tsx'
import LoadingAnimation from '@assets/Lottie/Loading.json'
import Lottie from 'lottie-react'

export const GeneratedDetails: FC<{project: Project; job: Job; minutesLeft: number | null}> = ({
    project,
    job,
    minutesLeft
}) => {
    const {t} = useTranslation()

    const seniorityToLabelTKey = {
        beginner: 'commons:seniority:beginner',
        intermediate: 'commons:seniority:middle',
        advanced: 'commons:seniority:senior'
    } as const satisfies Record<Seniority, string>

    const durationToLabelTKey = {
        short: 'commons:duration:short',
        medium: 'commons:duration:medium',
        long: 'commons:duration:long'
    } as const satisfies Record<Duration, string>

    return (
        <StyledDetails height="100%" direction="column" gap={8}>
            <Flexbox width="100%" direction="column" gap={4}>
                <StyledVideoWrapper>
                    {job.medias[0].status == 'audioGeneration' || job.medias[0].status == 'lipSync' ? (
                        <Flexbox justify="center" align="center" className="video-loading">
                            <Flexbox direction="column" justify="center" align="center">
                                <Lottie animationData={LoadingAnimation} className="animated-icon" />
                                <Flexbox direction="column" align="center" className="video-loading">
                                    <h3>{t('project:loading_video_title')}</h3>
                                    <span>
                                        {!!minutesLeft && minutesLeft >= 1
                                            ? t('project:loading_video_minutes_left', {count: minutesLeft})
                                            : t('project:loading_video_finishing')}
                                    </span>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                    ) : (
                        <video controls>
                            <source src={job.medias[0].metadata.videoUrl} type="video/mp4" />
                        </video>
                    )}
                </StyledVideoWrapper>
                <Flexbox className="video-info" align="center" gap={1}>
                    <InfoCircleIcon size={20} />
                    <small>{t('project:video_info')}</small>
                </Flexbox>
            </Flexbox>
            <Flexbox width="100%" direction="column" gap={4} className="generated-details">
                <GeneratedContentSectionTitle>{t('project:details_title')}</GeneratedContentSectionTitle>
                <Flexbox gap={4} direction="column">
                    <Flexbox gap={1.5} direction="column">
                        <h4>{t('project:topic')}</h4>
                        <p>{job.translatedPrompt}</p>
                    </Flexbox>
                    <Flexbox gap={1.5} direction="column">
                        <h4>{t('project:seniority')}</h4>
                        <p>{t(seniorityToLabelTKey[project.seniority])}</p>
                    </Flexbox>
                    <Flexbox gap={1.5} direction="column">
                        <h4>{t('project:duration')}</h4>
                        <p>{t(durationToLabelTKey[minutesToDuration(project.minutes)])}</p>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </StyledDetails>
    )
}

GeneratedDetails.displayName = 'GeneratedDetails'
