import React from 'react'
import {StyledContainer} from '@components/ui/Container/style.ts'

interface ContainerProps {
    className?: string
    children: React.ReactNode
    fullWidth?: boolean
    fullHeight?: boolean
}

const Container = ({children, className, fullWidth = false, fullHeight = true}: ContainerProps) => (
    <StyledContainer fullWidth={fullWidth} fullHeight={fullHeight} className={className}>
        {children}
    </StyledContainer>
)

export default Container

Container.displayName = 'Container'
