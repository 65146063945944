import Button from '@components/ui/Button'
import {StyledContainer, StyledErrorWrapper} from '@components/commons/error-box/style.ts'
import {FC, ReactNode} from 'react'

interface ErrorBoxProps {
    cta?: ReturnType<typeof Button>
    icon: ReactNode
    title: string
}

export const ErrorBox: FC<ErrorBoxProps> = ({cta, icon, title}) => {
    return (
        <StyledContainer fullHeight>
            <StyledErrorWrapper>
                {icon}
                <h1>{title}</h1>
                {cta}
            </StyledErrorWrapper>
        </StyledContainer>
    )
}

ErrorBox.displayName = 'ErrorBox'
