import {QueryClient} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 0,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: Infinity,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    PROJECT: 'project',
    LANGUAGES: 'languages'
} as const
