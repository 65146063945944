import styled, {css} from 'styled-components'
import {CSSProperties} from 'react'

type StyledSpinnerProps = {
    bgColor?: CSSProperties['borderColor']
    borderColor?: CSSProperties['borderColor']
    borderWidth?: CSSProperties['borderWidth']
    size: number
}
export const StyledSpinner = styled.span<StyledSpinnerProps>(
    ({bgColor, borderColor, borderWidth, size, theme: {palette}}) => css`
      width: ${size}px;
      height: ${size}px;
      border-style: solid;
      border-width: ${borderWidth ?? 3}px;
      border-color: ${bgColor ?? palette.neutral.white};
      border-bottom-color: ${borderColor ?? palette.primary['600']};
      border-left-color: ${borderColor ?? palette.primary['600']};
      border-radius: 50%;
      display: inline-block;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    `
)
