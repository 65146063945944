import styled, {css} from 'styled-components'
import Container from '@components/ui/Container'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'

export const StyledGeneratedContentView = styled(Container)(
    ({theme: {spacing, mediaQueries}}) => css`
        padding-top: ${spacing * 12}px;
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        height: 100%;
        overflow-y: hidden;

        ${mediaQueries.m} {
            display: block;
            height: max-content;
            overflow-y: auto;
        }
    `
)

export const StyledHeading = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        .success-title {
            font-size: ${typography.textMd};
            color: ${palette.neutral['900']};

            svg {
                fill: ${palette.primary['600']};
            }
        }

        h2 {
            font-size: ${typography.displayXs};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }

        button {
            flex: none;
        }
    `
)
export const StyledColumns = styled.div(
    ({theme: {palette, spacing, typography, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 750px 1fr;
        gap: ${spacing * 8}px;
        padding-top: ${spacing * 8}px;
        height: 100%;
        overflow-y: hidden;

        .content {
            display: grid;
            grid-template-rows: auto minmax(0, 1fr);
            height: 100%;
            overflow-y: hidden;
            gap: ${spacing * 6}px;

            .content-text {
                height: 100%;
                overflow-y: auto;
                padding-bottom: ${spacing * 8}px;
            }
        }

        h4 {
            font-size: ${typography.textMd};
            font-weight: 700;
            color: ${palette.neutral['600']};
        }

        p {
            font-size: ${typography.textMd};
            color: ${palette.neutral['900']};
        }

        ${mediaQueries.hl} {
            grid-template-columns: 600px 1fr;
        }
        ${mediaQueries.hm} {
            grid-template-columns: 480px 1fr;
        }
        ${mediaQueries.hs} {
            grid-template-columns: 300px 1fr;
        }

        ${mediaQueries.m} {
            grid-template-columns: 1fr;
            height: auto;
            overflow-y: auto;
        }
    `
)
