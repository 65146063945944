import styled, {css} from 'styled-components'

export const StyledLanguageModal = styled.div<{isLoadingOrError: boolean}>(
    ({theme: {typography, spacing, palette, mediaQueries}, isLoadingOrError}) => css`
        padding-top: ${spacing * 14}px;
        display: grid;
        grid-template-rows: ${isLoadingOrError ? '1fr' : 'auto minmax(0, 1fr)'};
        overflow-y: visible;
        height: 512px;

        .heading {
            border-bottom: 1px solid ${palette.neutral['200']};
            padding: 0 ${spacing * 6}px ${spacing * 4}px;
            h3 {
                font-size: ${typography.textXl};
                font-weight: 700;
                color: ${palette.neutral['900']};
            }
        }
        .list {
            height: max-content;
            max-height: 100%;
            padding: ${spacing * 6}px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: ${spacing * 8}px;
            overflow-y: auto;

            button {
                cursor: pointer;
                font-size: ${typography.textSm};
                color: ${palette.neutral['900']};
                padding: ${spacing * 2.5}px ${spacing * 4}px;
                border-radius: 8px;
                transition: ease-in-out 200ms;
                &:not(.current-language):hover {
                    background: ${palette.neutral['50']};
                }
                &.current-language {
                    border: 1px solid ${palette.neutral.black};
                }
            }

            ${mediaQueries.l} {
                grid-template-columns: repeat(3, 1fr);
            }
            ${mediaQueries.m} {
                grid-template-columns: repeat(2, 1fr);
            }
            ${mediaQueries.s} {
                grid-template-columns: 1fr;
            }
        }
        .no-results {
            padding: 0 ${spacing * 6}px;
            text-align: center;
            font-size: ${typography.textMd};
            color: ${palette.neutral['500']};
        }
    `
)
