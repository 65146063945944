import GenerationForm from '@/features/project/components/generation-form/GenerationForm.tsx'
import {GenerationHero} from '@/features/project/components/generation-hero/GenerationHero.tsx'
import {Helmet} from '@components/ui/helmet/Helmet.tsx'
import {useTranslation} from 'react-i18next'
import {StyledGeneration, StyledGenerationContent} from '@pages/generation/style.ts'
import Background from '@assets/images/generation-background.webp'
import Container from '@components/ui/Container'

const Generation = () => {
    const {t} = useTranslation()

    return (
        <StyledGeneration>
            <Helmet title={t('generation:helmet')} />
            <GenerationHero />
            <StyledGenerationContent bgImage={Background}>
                <Container className="generation-container">
                    <GenerationForm />
                    <div className="generation-background" />
                </Container>
            </StyledGenerationContent>
        </StyledGeneration>
    )
}

export default Generation

Generation.displayName = 'Generation'
