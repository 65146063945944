import {palette as mainPalette} from './palette.ts'
import mediaQueries from './mediaQueries.ts'
import ResetStyles from './reset.ts'
import {shadows} from './shadows.ts'
import {DefaultTheme} from 'styled-components'
import {typography} from './typography.ts'

const GlobalStyles = ResetStyles
export const spacing = 4

interface ThemeInterface {
    name: 'light'
    //todo: we will support the dark mode decommenting this one and adding the dark types to our style.d.ts
    /*name: 'light' | 'dark'*/
    direction?: 'ltr' | 'rtl'
}

const getAppTheme = ({name = 'light'}: ThemeInterface): DefaultTheme => {
    const palette = mainPalette[name]

    const zIndex = {
        modalOverlay: 10,
        modal: 11
    }

    return {
        spacing,
        zIndex,
        mediaQueries,
        palette,
        typography,
        shadows
    }
}

export {GlobalStyles, getAppTheme}
