import Modal from '@components/ui/Modal'
import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {StyledLanguageModal} from '@/features/languages/components/language-modal/style.ts'
import {APP_LANGUAGES} from '@/translations/i18n.ts'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {AlertCircleIcon, SearchMdIcon} from '@components/ui/Icon'
import {useTheme} from 'styled-components'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {breakpoints} from '@/theme/mediaQueries.ts'
import {useAsync} from '@hooks/useAsync.ts'
import {useMatch, useNavigate} from 'react-router-dom'
import toast from 'react-hot-toast'
import {httpCreateJob} from '@/features/project/services/project.http.ts'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {ProjectPageParams} from '@route-guards/ProjectRouteGuard.tsx'
import {ROUTES_PATHNAMES} from '@utilities/constants'
import {useLanguages} from '@/features/languages/queries/useLanguages.ts'
import {Spinner} from '@components/ui/Spinner/Spinner.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AxiosError} from 'axios'
import {ResponseError} from '@/types/commons.ts'
import {Job} from '@/features/project/types.ts'
import {useProject} from '@/features/project/queries/useProject.ts'

export const LanguageModal: FC<{onClose: () => void; language: string | undefined}> = ({language, onClose}) => {
    const {t} = useTranslation()
    const {width: viewportWidth} = useWindowDimensions()
    const languagesQuery = useLanguages()
    const currentLanguage = APP_LANGUAGES.find(lang => lang.value == language) ?? APP_LANGUAGES[0]
    const theme = useTheme()
    const navigate = useNavigate()
    const urlParams = useURLParsedParams(ProjectPageParams)
    const projectPage = useMatch(ROUTES_PATHNAMES.PROJECT)
    const projectQuery = useProject(String(projectPage?.params.projectId), String(projectPage?.params.jobId), {
        enabled: false
    })
    const createJob = useAsync()
    const [search, setSearch] = useState('')
    const languagesList = [
        currentLanguage,
        ...APP_LANGUAGES.filter(lang => lang.value != currentLanguage.value)
    ].filter(
        lang =>
            lang.label.toLowerCase().includes(search.toLowerCase()) ||
            lang.value.toLowerCase().includes(search.toLowerCase())
    )

    const onChangeLanguage = async (nextLanguage: string) => {
        //await changeAppLanguage(nextLanguage)
        const nextLanguageId = languagesQuery.data?.find(language => language.iso_639_1 == nextLanguage)?.id

        if (projectPage && urlParams.success && nextLanguageId) {
            await createJob.run(
                httpCreateJob(urlParams.data.projectId, {targetLanguageId: nextLanguageId, mediaTypes: ['video']})
                    .then(response => {
                        const data = Job.parse(response.data)

                        navigate(
                            ROUTES_PATHNAMES.PROJECT.replace(':projectId', String(data.projectId)).replace(
                                ':jobId',
                                String(data.id)
                            )
                        )
                    })
                    .catch((error: AxiosError<ResponseError>) => {
                        if (
                            error.response?.data.errors.length == 1 &&
                            error.response.data.errors[0].field == 'targetLanguageId' &&
                            error.response.data.errors[0].message == 'mustNotAlreadyExist'
                        ) {
                            const jobId = projectQuery.data?.jobs.find(
                                job => job.targetLanguage.iso_639_1 == nextLanguage
                            )?.id
                            if (jobId) {
                                navigate(
                                    ROUTES_PATHNAMES.PROJECT.replace(
                                        ':projectId',
                                        String(projectPage.params.projectId)
                                    ).replace(':jobId', String(jobId))
                                )
                            }
                        } else {
                            toast.error(t('errors:default'))
                        }
                    })
            )
            onClose()
        } else {
            onClose()
        }
    }

    return (
        <Modal width={960} closeIconOffset="sm" onClose={onClose}>
            <StyledLanguageModal
                isLoadingOrError={languagesQuery.isLoading || createJob.isLoading || languagesQuery.isError}
            >
                {languagesQuery.isError ? (
                    <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
                ) : languagesQuery.isLoading || createJob.isLoading ? (
                    <Flexbox height="100%" width="100%" justify="center" align="center" className="no-results">
                        <Spinner size={32} />
                    </Flexbox>
                ) : (
                    <>
                        <Flexbox
                            className="heading"
                            width="100%"
                            justify="space-between"
                            align="center"
                            gap={4}
                            direction={viewportWidth <= breakpoints.width.xs ? 'column' : 'row'}
                        >
                            <h3>{t('commons:choose_language')}</h3>
                            <InputText
                                inputSize="sm"
                                placeholder={String(t('commons:search_languages'))}
                                type="text"
                                width={viewportWidth <= breakpoints.width.xs ? '100%' : '248px'}
                                typeIcon={<SearchMdIcon size={20} fill={theme.palette.neutral['500']} />}
                                onChange={event => setSearch(event.target.value)}
                            />
                        </Flexbox>
                        {languagesList.length >= 1 ? (
                            <div className="list">
                                {languagesList.map(lang => (
                                    <button
                                        className={lang.value == currentLanguage.value ? 'current-language' : ''}
                                        onClick={() => onChangeLanguage(lang.value)}
                                        key={lang.value}
                                    >
                                        {lang.label}
                                    </button>
                                ))}
                            </div>
                        ) : (
                            <Flexbox height="100%" width="100%" justify="center" align="center" className="no-results">
                                {t('commons:no_language_results')}
                            </Flexbox>
                        )}
                    </>
                )}
            </StyledLanguageModal>
        </Modal>
    )
}

LanguageModal.displayName = 'LanguageModal'
