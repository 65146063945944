import {AlertCircleIcon, CheckCircleIcon} from '@components/ui/Icon'
import {InputStatusIconWrapper} from '@components/ui/InputStatusIcon/style.ts'

interface InputStatusIconProps {
    hasError?: boolean
    touched?: boolean
}
const InputStatusIcon = ({hasError, touched}: InputStatusIconProps) => {
    return (
        <InputStatusIconWrapper hasError={hasError}>
            {hasError && <AlertCircleIcon size={16} data-color />}
            {touched && !hasError && <CheckCircleIcon size={16} data-color />}
        </InputStatusIconWrapper>
    )
}

export default InputStatusIcon

InputStatusIcon.displayName = 'InputStatusIcon'
