import styled, {css} from 'styled-components'

export const StyledFooter = styled.footer`
    ${({theme: {palette, spacing, typography}}) => css`
        height: 100px;
        background: ${palette.neutral['100']};
        padding: ${spacing * 3}px 0;

        .footer-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: ${spacing * 2}px;
            font-size: ${typography.textXs};
            color: ${palette.neutral['500']};
        }

        .logo {
            height: 34px;
        }

        .translated-logo {
            width: 112px;
        }
    `}
`
