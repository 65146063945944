import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
//translations
import af from './af/af.json'
import ar from './ar/ar.json'
import da from './da/da.json'
import de from './de/de.json'
import en from './en/en.json'
import es_419 from './es-419/es-419.json'
import fi from './fi/fi.json'
import fr from './fr/fr.json'
import hi from './hi/hi.json'
import hr from './hr/hr.json'
import it from './it/it.json'
import ja from './ja/ja.json'
import ko from './ko/ko.json'
import nl from './nl/nl.json'
import pl from './pl/pl.json'
import pt_BR from './pt-BR/pt-BR.json'
import pt_PT from './pt-PT/pt-PT.json'
import ru from './ru/ru.json'
import zh from './zh/zh.json'
import zh_CN from './zh-CN/zh-CN.json'

export const APP_LANGUAGES = [
    {label: 'Afrikaans', value: 'af', json: af},
    {label: 'اَلْعَرَبِيَّة', value: 'ar', json: ar},
    {label: 'Dansk', value: 'da', json: da},
    {label: 'Deutsch', value: 'de', json: de},
    {label: 'English', value: 'en', json: en},
    {label: 'Español (América Latina)', value: 'es-419', json: es_419},
    {label: 'Suomi', value: 'fi', json: fi},
    {label: 'Français', value: 'fr', json: fr},
    {label: 'हिन्दी', value: 'hi', json: hi},
    {label: 'Hrvatski', value: 'hr', json: hr},
    {label: 'Italiano', value: 'it', json: it},
    {label: '日本語', value: 'ja', json: ja},
    {label: '한국어', value: 'ko', json: ko},
    {label: 'Nederlands', value: 'nl', json: nl},
    {label: 'Polski', value: 'pl', json: pl},
    {label: 'Português (Brasil)', value: 'pt-BR', json: pt_BR},
    {label: 'Português (Portugal)', value: 'pt-PT', json: pt_PT},
    {label: 'Русский', value: 'ru', json: ru},
    {label: '漢文', value: 'zh', json: zh},
    {label: '汉语', value: 'zh-CN', json: zh_CN}
] as const

export const resources = Object.fromEntries(APP_LANGUAGES.map(language => [language.value, language.json]))

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: APP_LANGUAGES.map(language => language.value),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng'
        }
    })

export const changeAppLanguage = async (lang: string) => {
    await i18n.changeLanguage(lang)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    await window.OneTrust?.changeLanguage(lang)
}

export default i18n
