import {z} from 'zod'

/*const boolEnvvar = z
    .string()
    .optional()
    .transform(s => s == 'true')*/

export const envVars = z
    .object({
        VITE_API_ENDPOINT: z.string().url()
    })
    .parse(import.meta.env)
