import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/Flexbox.tsx'

export const StyledDetails = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        overflow-y: auto;
        padding-bottom: ${spacing * 8}px;

        .video-info {
            font-size: ${typography.textXs};
            color: ${palette.neutral['500']};
        }

        h4 {
            font-size: ${typography.textMd};
            font-weight: 700;
            color: ${palette.neutral['600']};
        }

        p {
            font-size: ${typography.textMd};
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledVideoWrapper = styled.div(
    ({theme: {mediaQueries, spacing, palette, typography}}) => css`
        width: 100%;
        aspect-ratio: 16 / 9;
        overflow-y: hidden;

        video,
        .video-loading {
            width: 100%;
            height: 100%;
        }

        .video-loading {
            gap: ${spacing * 8}px;
            background: ${palette.neutral['50']};

            .animated-icon {
                height: 150px;
            }

            h3 {
                max-width: 350px;
                font-size: ${typography.displaySm};
                font-weight: 700;
                color: ${palette.neutral['900']};
                text-align: center;
            }

            span {
                font-size: ${typography.textMd};
                color: ${palette.neutral['500']};
                text-align: center;
            }
        }

        ${mediaQueries.hl} {
            .video-loading {
                gap: ${spacing * 6}px;
            }
        }
        ${mediaQueries.hm} {
            .video-loading {
                gap: ${spacing * 4}px;
                .animated-icon {
                    height: 120px;
                }
                h3 {
                    font-size: ${typography.displayXs};
                }
            }
        }
        ${mediaQueries.hs} {
            .video-loading {
                gap: ${spacing * 2}px;
                .animated-icon {
                    height: 80px;
                }
                h3 {
                    font-size: ${typography.textLg};
                }
                span {
                    font-size: ${typography.textSm};
                }
            }
        }

        ${mediaQueries.s} {
            .video-loading {
                gap: ${spacing * 4}px;

                .animated-icon {
                    height: 120px;
                }
                h3 {
                    font-size: ${typography.displayXs};
                }
                span {
                    font-size: ${typography.textSm};
                }
            }
        }
        ${mediaQueries.xs} {
            .video-loading {
                gap: ${spacing * 2}px;

                .animated-icon {
                    height: 80px;
                }
                h3 {
                    font-size: ${typography.textLg};
                }
            }
        }
    `
)
