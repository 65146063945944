import styled, {css} from 'styled-components'

export const StyledGenerationForm = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        padding: ${spacing * 14}px 0 ${spacing * 14}px;

        h3 {
            font-size: ${typography.textLg};
            font-weight: 700;
            color: ${palette.neutral['900']};
        }
    `
)
