import {z} from 'zod'
import {AlertCircleIcon} from '@components/ui/Icon'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {Outlet} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

export const ProjectPageParams = z.object({
    projectId: z.string().min(1),
    jobId: z.string().min(1)
})
export type ProjectPageParams = z.infer<typeof ProjectPageParams>

export const ProjectRouteGuard = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(ProjectPageParams)

    return urlParams.success ? (
        <Outlet context={{urlParams: urlParams.data}} />
    ) : (
        <ErrorBox icon={<AlertCircleIcon />} title={t('project:invalid_link')} />
    )
}
